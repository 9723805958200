<template>
  <section id="check">
    <div class="checkout_wrap">
      <div class="pay_list_wrap">
        <div class="pay_title">{{ $t("main.myUsageHistory") }}</div>
        <dl class="pay_list bg">
          <dt>{{ $t("main.selfUsageHistory", [payerName]) }}</dt>
          <dd>{{ $t("common.won", [numberWithCommas(totAmt)]) }}</dd>
        </dl>
        <dl class="pay_list">
          <dt>{{ $t("main.paymentAmount") }}</dt>
          <dd class="c_r">
            {{ $t("common.won", [numberWithCommas(paidAmt)]) }}
          </dd>
          <dt>{{ $t("main.remainingPaymentAmount") }}</dt>
          <dd>{{ $t("common.won", [numberWithCommas(remainAmt)]) }}</dd>
        </dl>
      </div>
      <div class="pay_list_wrap line_none pd_b0">
        <!-- <p class="stitle stitle_small">
          {{ $t("payMessage.partialPayment") }}
        </p> -->
        <dl class="pay_list">
          <div v-for="(item, index) in myPayList" :key="index">
            <dt>
              <!-- <input name="" class="checkbox_basic" type="checkbox" checked /> -->
              {{ item.productName }}
            </dt>
            <dd>{{ $t("common.won", [numberWithCommas(item.totAmt)]) }}</dd>
          </div>
        </dl>
      </div>
      <div class="btn_pay_wrap">
        <a class="btn_point" @click="onClickGoPayPage">
          {{ $t("common.submit") }}
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import checkOutMixin from "@/mixins/checkOutMixin";

export default {
  name: "PayMy",
  mixins: [checkOutMixin],
};
</script>
